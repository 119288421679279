.sidebar {
}

.sidebar :global(.nav) {
  display: flex;
  flex-direction: column;
}

.sidebar :global(.sideBar-item) {
  display: flex;
  flex-direction: column;
}

.sidebar :global(.NavLink-elements) {
  padding: 10px;
}

.sidebar :global(.logo) img {
  width: 180px;
  transition: all 0.3s;
  margin-left: 30px;
}

.sidebar :global(.logo) :global(.title) {
  transition: all 1s;
  display: inline-flex;
  flex-direction: column;
  line-height: 26px;
  font-size: 22px;
  margin-left: 28px;
  color: #fff;
}

.sidebar :global(.sidebar-wrapper) {
  overflow-x: hidden;
  overflow-y: auto;
}

.sidebar :global(.sidebar-wrapper)::-webkit-scrollbar-track {
  background-color: #000;
}

.sidebar :global(.sidebar-wrapper)::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.sidebar :global(.sidebar-wrapper)::-webkit-scrollbar-thumb {
  background-color: #666;
}
