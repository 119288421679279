.images .img {
  border-width: 1px;
  border-style: solid;
  object-fit: cover;
  margin-bottom: 2rem;
  width: 100%;
  height: 12rem;
  border-width: 1px;
  border-style: solid;
}

.images .logo {
  display: flex;
  justify-content: center;
}

.images .logo .img {
  width: 12rem;
  margin-left: auto;
  margin-right: auto;
}

.images .cover {
  display: flex;
  justify-content: center;
}

.images .cover .img {
  width: 100%;
}
