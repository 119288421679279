.image-gallery {
  .image-gallery-slides {
    .image-gallery-slide {
      padding-top: 66%;

      .image-gallery-image {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

.image-gallery-left-nav,
.image-gallery-right-nav {
  padding: 0px;
}
