@-webkit-keyframes bounce-pulse {
  0% {
    transform: translateY(0.1875em);
  }
  30% {
    transform: translateY(-0.25em);
  }
  50% {
    transform: translateY(0.1875em);
  }
}
@keyframes bounce-pulse {
  0% {
    transform: translateY(0.1875em);
  }
  30% {
    transform: translateY(-0.25em);
  }
  50% {
    transform: translateY(0.1875em);
  }
}
.rai-bounce {
  display: inline-block;
  line-height: 0;
}
.rai-bounce > div {
  -webkit-animation-duration: inherit;
  animation-duration: inherit;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: bounce-pulse;
  animation-name: bounce-pulse;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  background-color: currentColor;
  border-radius: 100%;
  display: inline-block;
  height: 0.5em;
  margin-right: 0.1875em;
  transform: translateY(0.1875em);
  width: 0.5em;
}
.rai-bounce > div:last-child {
  margin-right: 0;
}
