.fit-cover {
  object-fit: cover;
}

.fit-contain {
  object-fit: contain;
}

.fit-fill {
  object-fit: fill;
}

.fit-none {
  object-fit: none;
}

.fit-scale {
  object-fit: scale-down;
}

.flex-2 {
  flex: 2 1;
}

.flex-3 {
  flex: 3 1;
}
