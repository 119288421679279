/**
 * The geosuggest module
 * NOTE: duplicated font-sizes' are for browsers which don't support rem (only IE 8)
 */
.geosuggest {
  position: relative;
  text-align: left;
}

.geosuggest__suggests {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 25em;
  padding: 0;
  margin-top: -0.5rem;
  background: #fff;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  z-index: 5;
}

.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
}

/**
 * A geosuggest item
 */
.geosuggest__item {
  font-size: 18px;
  font-size: 1rem;
  padding: 0.5em 0.65em;
  cursor: pointer;
}

.geosuggest__item:hover,
.geosuggest__item:focus {
  background: #f5f5f5;
}

.geosuggest__item--active {
  background: #267dc0;
  color: #fff;
}

.geosuggest__item--active:hover,
.geosuggest__item--active:focus {
  background: #ccc;
}

.geosuggest__item__matched-text {
  font-weight: bold;
}
