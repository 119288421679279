/* @tailwind base; */
/* @tailwind components; */
@tailwind utilities;

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Material+Icons');
@import './utilities.css';
@import 'react-image-crop/dist/ReactCrop.css';

body {
  margin: 0;
  padding: 0;
}

body *,
*:focus {
  outline: none;
}

.form-group.is-focused .form-control {
  outline: none;
  background-image: linear-gradient(#26c1d5, #64bf88), linear-gradient(#d2d2d2, #d2d2d2);
  background-size: 100% 2, 100% 1;
  box-shadow: none;
  transition-duration: 0.3s;
}

.sidebar[data-active-color='purple'] li > a.active {
  background-color: #9c27b0;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(156, 39, 176, 0.4);
}

.sidebar[data-active-color='blue'] li > a.active {
  background-color: #00bcd4;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(0, 188, 212, 0.4);
}

.sidebar[data-active-color='green'] li > a.active {
  background-color: #4caf50;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(76, 175, 80, 0.4);
}

.sidebar[data-active-color='orange'] li > a.active {
  background-color: #ff9800;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(255, 152, 0, 0.4);
}

.sidebar[data-active-color='red'] li > a.active {
  background-color: #f44336;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(244, 67, 54, 0.4);
}

.sidebar[data-active-color='rose'] li > a.active {
  background-color: #e91e63;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4);
}

.sidebar[data-active-color='white'] li > a.active {
  background-color: #ffffff;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(255, 255, 255, 0.4);
}

.sidebar[data-active-color='white'] .nav li > a.active:not([data-toggle='collapse']) {
  color: #3c4858;
  opacity: 1;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(60, 72, 88, 0.4);
}

.sidebar[data-active-color='white'] .nav li > a.active:not([data-toggle='collapse']) i {
  color: rgba(60, 72, 88, 0.8);
}

.sidebar[data-background-color='red'] .nav li.active > [data-toggle='collapse'],
.sidebar[data-background-color='red'] .nav li:hover > [data-toggle='collapse'] {
  color: #ffffff;
}

.sidebar[data-background-color='red'] .nav li.active > [data-toggle='collapse'] i,
.sidebar[data-background-color='red'] .nav li:hover > [data-toggle='collapse'] i {
  color: rgba(255, 255, 255, 0.8);
}

.sidebar[data-background-color='red'] .nav li:hover > a:not(.active),
.sidebar[data-background-color='red'] .nav li.active > [data-toggle='collapse'] {
  background-color: rgba(255, 255, 255, 0.1);
}

.card .card-header.card-header-text .card-title {
  display: flex;
}

.card .card-header.card-header-text .card-title i.material-icons {
  margin-right: 15px;
}

.card.card-animated {
  -webkit-transition: all 200ms linear;
  -moz-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  border-radius: 6px;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.card.card-animated.card-hidden {
  opacity: 0;
  -webkit-transform: translate3d(0, -60px, 0);
  -moz-transform: translate3d(0, -60px, 0);
  -o-transform: translate3d(0, -60px, 0);
  -ms-transform: translate3d(0, -60px, 0);
  transform: translate3d(0, -60px, 0);
}

.col-xs-offset-right-12 {
  margin-right: 100%;
}

.col-xs-offset-right-11 {
  margin-right: 91.66666667%;
}

.col-xs-offset-right-10 {
  margin-right: 83.33333333%;
}

.col-xs-offset-right-9 {
  margin-right: 75%;
}

.col-xs-offset-right-8 {
  margin-right: 66.66666667%;
}

.col-xs-offset-right-7 {
  margin-right: 58.33333333%;
}

.col-xs-offset-right-6 {
  margin-right: 50%;
}

.col-xs-offset-right-5 {
  margin-right: 41.66666667%;
}

.col-xs-offset-right-4 {
  margin-right: 33.33333333%;
}

.col-xs-offset-right-3 {
  margin-right: 25%;
}

.col-xs-offset-right-2 {
  margin-right: 16.66666667%;
}

.col-xs-offset-right-1 {
  margin-right: 8.33333333%;
}

.col-xs-offset-right-0 {
  margin-right: 0;
}
@media (min-width: 768px) {
  .col-sm-offset-right-12 {
    margin-right: 100%;
  }

  .col-sm-offset-right-11 {
    margin-right: 91.66666667%;
  }

  .col-sm-offset-right-10 {
    margin-right: 83.33333333%;
  }

  .col-sm-offset-right-9 {
    margin-right: 75%;
  }

  .col-sm-offset-right-8 {
    margin-right: 66.66666667%;
  }

  .col-sm-offset-right-7 {
    margin-right: 58.33333333%;
  }

  .col-sm-offset-right-6 {
    margin-right: 50%;
  }

  .col-sm-offset-right-5 {
    margin-right: 41.66666667%;
  }

  .col-sm-offset-right-4 {
    margin-right: 33.33333333%;
  }

  .col-sm-offset-right-3 {
    margin-right: 25%;
  }

  .col-sm-offset-right-2 {
    margin-right: 16.66666667%;
  }

  .col-sm-offset-right-1 {
    margin-right: 8.33333333%;
  }

  .col-sm-offset-right-0 {
    margin-right: 0;
  }
}
@media (min-width: 992px) {
  .col-md-offset-right-12 {
    margin-right: 100%;
  }

  .col-md-offset-right-11 {
    margin-right: 91.66666667%;
  }

  .col-md-offset-right-10 {
    margin-right: 83.33333333%;
  }

  .col-md-offset-right-9 {
    margin-right: 75%;
  }

  .col-md-offset-right-8 {
    margin-right: 66.66666667%;
  }

  .col-md-offset-right-7 {
    margin-right: 58.33333333%;
  }

  .col-md-offset-right-6 {
    margin-right: 50%;
  }

  .col-md-offset-right-5 {
    margin-right: 41.66666667%;
  }

  .col-md-offset-right-4 {
    margin-right: 33.33333333%;
  }

  .col-md-offset-right-3 {
    margin-right: 25%;
  }

  .col-md-offset-right-2 {
    margin-right: 16.66666667%;
  }

  .col-md-offset-right-1 {
    margin-right: 8.33333333%;
  }

  .col-md-offset-right-0 {
    margin-right: 0;
  }
}
@media (min-width: 1200px) {
  .col-lg-offset-right-12 {
    margin-right: 100%;
  }

  .col-lg-offset-right-11 {
    margin-right: 91.66666667%;
  }

  .col-lg-offset-right-10 {
    margin-right: 83.33333333%;
  }

  .col-lg-offset-right-9 {
    margin-right: 75%;
  }

  .col-lg-offset-right-8 {
    margin-right: 66.66666667%;
  }

  .col-lg-offset-right-7 {
    margin-right: 58.33333333%;
  }

  .col-lg-offset-right-6 {
    margin-right: 50%;
  }

  .col-lg-offset-right-5 {
    margin-right: 41.66666667%;
  }

  .col-lg-offset-right-4 {
    margin-right: 33.33333333%;
  }

  .col-lg-offset-right-3 {
    margin-right: 25%;
  }

  .col-lg-offset-right-2 {
    margin-right: 16.66666667%;
  }

  .col-lg-offset-right-1 {
    margin-right: 8.33333333%;
  }

  .col-lg-offset-right-0 {
    margin-right: 0;
  }
}

.form-group:not(.is-focused) textarea.form-control {
  background-image: none;
}

.form-group textarea.form-control {
  border: 1px solid #d2d2d2;
  padding: 5px;
}

td.td-actions .btn {
  margin: 0px 2px !important;
}

.btn i {
  display: initial !important;
}

.btn-secondary {
  background-color: #21b798;
}

.page-link {
  cursor: pointer;
}
button.page-link {
  background-color: transparent;
  border: 0;
}

.customModal {
  width: 80vw;
  max-height: 85vh;
}

.customModalDatePicker {
  width: 48vw;
  max-width: 48vw;
}

.customEventModal {
  width: 70vw;
  max-width: 80vw;
}

.memberDetailsModal {
  width: 40vw;
  max-width: 40vw;
}

.cropperModal {
  width: auto;
  min-width: 25vw;
  max-width: 70vw;
}

.modal-backdrop {
  background: rgba(0, 0, 0, 0.6);
}

.modal-backdrop.fade {
  opacity: 0.5;
}

.react-datepicker {
  font-size: 1em;

  .react-datepicker__header {
    padding-top: 0.8em;
  }

  .react-datepicker__month {
    margin: 0.4em 1em;
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    width: 1.9em;
    line-height: 1.9em;
    margin: 0.166em;
  }

  .react-datepicker__current-month {
    font-size: 1em;
  }

  .react-datepicker__navigation {
    top: 1em;
    line-height: 1.7em;
    border: 0.45em solid transparent;
  }

  .react-datepicker__navigation--previous {
    border-right-color: #ccc;
    left: 1em;
  }

  .react-datepicker__navigation--next {
    border-left-color: #ccc;
    right: 1em;
  }
}

.dark-theme {
  div[class^='MuiInput'] {
    &:before {
      border-color: white;
    }
  }

  svg[class^='MuiSvgIcon'] {
    color: white;
  }
}

.deleteMediaCross {
  font-size: 42px !important;
  opacity: 0.7;
  position: relative;
  top: 48px;
  right: 68px;
}

.notifications-button:hover {
  cursor: pointer;
}

.notifications-header {
  display: flex;
  justify-content: space-between;
}

.notifications-header-bold {
  margin-left: 15px;
  font-weight: 500;
}

.notifications-header-markAllRead {
  margin-right: 15px;
  font-weight: 500;
}

.notifications-header-markAllRead:hover {
  text-decoration: underline;
  cursor: pointer;
}

.notifications-footer {
  display: flex;
  justify-content: center;
}

.notifications-footer-text {
  font-weight: 500;
}

.notifications-footer-text:hover {
  text-decoration: underline;
  cursor: pointer;
}

.notifications-container {
  width: 400px;
  z-index: 9999 !important;
  height: 350px;
}

.notifications-hr-header {
  margin: 5px 0px 0px 0px;
}

.notifications-hr-footer {
  margin: 0px 0px 7px 0px;
}

.notifications-menulist {
  padding: 0px !important;
  height: 300px;
  overflow-y: auto;
}

.notifications-menuitem {
  margin: 5px 0px !important;
}

.notif-unread {
  color: #6a7689;
  opacity: 0.5;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
}

.notif-read {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
}

.contacts {
  display: flex;
  flex-direction: column;

  background-color: #edf2fa;
}

.notifications-menulist::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  background-color: #f5f5f5;
  border-radius: 10px;
}

.notifications-menulist::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

.notifications-menulist::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: -webkit-linear-gradient(60deg, rgb(102, 187, 106), rgb(67, 160, 71));
}

#smoothScroll {
  scroll-behavior: smooth;
}

.bp4-transition-container {
  z-index: 8000;
}

.bp4-popover {
  width: 620px;
}

.bp4-popover .bp4-popover-content-sizing .bp4-popover-content {
  max-width: 650px;
}

.swal2-input {
  width: 150px !important;
}

.simple-autopilot-stats {
  color: #9a9a9a;
  font-size: 12px;
  padding: 12px 8px;
  height: 58px;
}

.reward-stats {
  color: #9a9a9a;
  font-size: 14px;
  padding: 12px 8px;
}

.card-config {
  min-height: 60px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  top: -55px;
  margin: 0px;
}

.card-children {
  position: relative;
  margin-top: -55px;
}

.event-description {
  display: flex;
  flex-direction: row;
}

.rich-text-editor {
  min-width: 603px;
  max-width: 603px;
}

.table-color-grid:nth-child(even) {
  background-color: #f9f9f9;
}

.table-color-grid:nth-child(odd) {
  background-color: white;
}

.map-image {
  height: 20rem;
  margin-bottom: 2rem;
  border-width: 1px;
  border-style: solid;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.cover-image,
.logo-image {
  margin-bottom: 2rem;
  height: 14rem;
  border-width: 1px;
  border-style: solid;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.cover-image {
  width: 100%;
}

.logo-image {
  width: 14rem;
}

.logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.owner {
  color: #ffd700;
  text-align: center;
  font-weight: 500;
  text-shadow: 2px 2px #000;
}

.social-header {
  background-size: contain !important;
}

.social-header-logo {
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}

.badge {
  color: white;
  padding: 10px;
  border-radius: 8px;
  width: max-content;
}

.zoom-image {
  width: fit-content;
  position: relative;
}

.zoom-image .zoom-overlay {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s, opacity 0.5s ease;
}
.zoom-image:hover .zoom-overlay {
  opacity: 0.4;
  background-color: #000;
  visibility: visible;
}

.select__value-container--has-value {
  overflow: visible !important;
}

.rbc-event {
  background-color: initial !important;
  border: none !important;
}

.sticky-header th {
  top: 0;
  position: sticky;
  background-color: #fff;
  border-bottom: none !important;
  z-index: 2;
}

.sticky-border th {
  box-shadow: inset 0 1px 0 #ddd, inset 0 -1px 0 #ddd;
}

.sticky-border-bottom th {
  box-shadow: inset 0px -1px 0 #ddd, inset 0 -1px 0 #ddd;
}

.popover-btn {
  padding: 0;
  margin: 3px;
  height: 30px;
  width: 30px;
}

.advanced-list-actions {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  border-bottom: 1px solid #f7f7f7;
}

.advanced-list-button {
  display: inline-block;
}

.customData {
  font-weight: normal;
}

.linkedData {
  display: inline-block;
  font-weight: normal;
  margin: auto;
  border-bottom: 1px solid transparent;
}

.linkedData:hover {
  cursor: pointer;
  border-bottom: 1px solid black;
}

.advanced-list-actions:hover {
  background-color: #f5f5f5;
}

.sentToModal {
  width: 75% !important;
}

.sentToLabel {
  color: #9c27b0;
  font-size: 18;
  font-weight: 500;
  min-height: 40px;
  display: flex;
  align-items: center;
}

.sentToValue {
  border-bottom: 0.5px solid lightgray;
  display: flex;
  align-items: center;
  min-height: 50px;
}

.sentToBadgeValue {
  color: white;
  padding: 5px 15px;
  border-radius: 20px;
  width: max-content;
  align-self: center;
  position: relative;
  left: -5px;
}

.selectShowMore {
  margin-left: 10px;
  color: darkgrey;
}

.selectShowMore:hover {
  cursor: pointer;
  font-weight: bold;
}

.selectMembersTable > tbody > tr > td,
.selectMembersTable > thead > tr > th {
  padding: 0px !important;
}

.settlementsTable > tbody > tr > td {
  padding: 2px !important;
}

.breadcrumbItem {
  color: #7e7e7e;
}

.breadcrumbItem:hover {
  border-bottom: #a7a7a7 1px solid;
}

.customDatePickerInput {
  box-shadow: none !important;
  border-bottom: 1px solid #858585;
  border-radius: 0;
  font-size: 15px;
}

.customDatePickerInput:hover {
  border-bottom: 2px solid #000000;
  top: 1px;
}

.MuiChip-colorPrimary {
  background-color: #4caf50 !important;
}

@media (max-width: 1280px) {
  .event-description {
    flex-direction: column;
  }
  .event-preview {
    min-width: 603px;
    max-width: 603px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
  }
  .memberDetailsModal {
    width: 55vw;
    max-width: 55vw;
  }
}

@media (max-width: 960px) {
  .social-header {
    grid-template-columns: 100% !important;
    grid-template-rows: 1fr auto !important;
  }
  .social-avatar {
    grid-column: 1 !important;
    grid-row: 1 !important;
  }
  .text-align-mobile {
    text-align: center;
  }
  .social-stats > div {
    left: 0 !important;
  }
}

@media (min-width: 960px) {
  .MuiTabs-flexContainer {
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .card-config {
    justify-content: center;
    top: 0;
  }
  .card-children {
    margin-top: 0;
  }
  .rich-text-editor {
    min-width: 100%;
    max-width: 100%;
  }
  .event-preview {
    min-width: 100%;
    max-width: 100%;
  }
  .image-ratio-cover {
    display: block;
    padding-top: 50%;
    height: initial !important;
  }
  .image-ratio-map {
    display: block;
    padding-top: 70%;
    height: initial !important;
  }
  .cover-margin {
    margin-top: 15px;
  }
  .memberDetailsModal {
    width: 85vw;
    max-width: 85vw;
  }
  .responsive-modal {
    width: 85vw;
  }
}

@media (max-width: 500px) {
  .navbar-mobile {
    margin-top: 8px;
    height: 110px;
  }
  .text-align-mobile {
    text-align: center;
  }
  .shrink-mobile {
    max-width: 175px;
  }
  .btn {
    white-space: normal;
  }
}
