.description :global(.card) {
  height: auto;
  flex: 1;
}

.description :global(.card) :global(.card-content) {
  height: 100%;
}

.description :global(.card) :global(.form-horizontal) {
  height: 100%;
}

.phonePreviewWrap {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: absolute;
  top: 47px;
  left: 165px;
  height: 354px;
  width: 201px;
  word-wrap: break-word;
  overflow-x: hidden;
  overflow-y: auto;
}

.phonePreviewWrap::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.phonePreviewWrap::-webkit-scrollbar-thumb {
  background-color: #64bf88;
}

.phonePreviewWrap .phonePreviewImage {
  top: 0;
  background: dimgray;
  object-fit: cover;
  position: absolute;
  filter: brightness(50%);
  width: 100%;
  height: 70px;
}

.phonePreviewWrap .phonePreviewTitle {
  height: 30px;
  position: relative;
  color: #18c0e4;
  text-align: center;
  font-weight: 300;
  margin: 10px 0px;
  font-size: 15px;
  letter-spacing: 0.3;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  border-style: hidden hidden solid hidden;
  border-bottom-width: 1px;
  border-bottom-color: #ccddee;
  padding: 0px 15px 8px 18px;
  box-shadow: 0px 0.5px #ccddee;
}

.phonePreviewWrap .phonePreviewImage {
  position: relative;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 20px;
  padding: 0;
  line-height: 10px;
  font-weight: 500;
  color: #2fc5c5;
  font-size: 11px;
}

.phonePreviewWrap .phonePreviewContent {
  font-size: 10px;
  margin-left: 10px;
  margin-right: 8px;
  line-height: 1.4em;
}

.images .img {
  object-fit: cover;
  margin-bottom: 2rem;
  width: 100%;
  height: 12rem;
  border-width: 1px;
  border-style: solid;
}

.images .cover {
  display: flex;
  justify-content: center;
}

.images .cover .img {
  width: 100%;
}
