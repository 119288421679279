.navAuth .brand {
  height: 100% !important;
  max-width: 340px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.navAuth .brand img {
  max-width: 180px;
  margin: 0 15px 15px 0;
}

.navAuth .navigation {
  margin-top: 25px;
}

.select {
  color: white !important;
  border-bottom: 2px solid white !important;
}

.icon {
  color: white !important;
}
