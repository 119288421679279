.card {
}

.content {
  padding: 10px 30px 10px 10px !important;
}

.header {
  padding: 2px !important;
  margin-top: -18px !important;
  margin-bottom: 10px !important;
  transition: all 0.15s;
}

.up {
  transform: translate3d(0, -24px, 0);
}
